const config = {
    baseUrl: '/',
    baseUrl2: 'https://orders-staging.fastenerwarehouse.com.au/',
    //~ apiUrl: "http://localhost:3008/api/",
    //~ imageUrl: 'http://localhost:3008/api/uploads/',
    //~ bannersUrl: 'http://localhost:3008/api/banners/',   
    apiUrl: "https://orders-staging.fastenerwarehouse.com.au/apis/api/",
    imageUrl: 'https://orders-staging.fastenerwarehouse.com.au/apis/api/uploads/',
    bannersUrl: 'https://orders-staging.fastenerwarehouse.com.au/apis/api/banners/',     
    finish: 1,
    material: 2,
    thread: 3,
    paint: 4,
    drive: 5,
    visible: 10,
    superAdmin: 1,
    paging_size: 20,
    //SQUARE_APPLICATION_ID: "sandbox-sq0idb-uhQ7hmSU8Mgzl0BFDClGKQ", // GS   
    //SQUARE_LOCATION_ID:'L5K68AKZ90ACG',// GS   
    //SQUARE_APPLICATION_ID: "sandbox-sq0idb-nALHFqu8N0Hg29X-FVpNyA",  sandbox
    //SQUARE_LOCATION_ID:'L3HFX62EBB4AZ', //sandbox
    SQUARE_APPLICATION_ID: "sq0idp-rx-AP_BJCIGKvndPIjf_9g",  
    SQUARE_LOCATION_ID:'L520M17QJ0RCT',
}
export default config;
